import React, { useState, useRef } from 'react';
import { convertTimeIntoMMYYYY } from '../../../utils/dateFilter/DateFilter';
import { FaBook, FaGithub, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { TbWorldWww  } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import UpdateInput from '../../../components/modal/UpdateInput';
import { ModalBox } from '../../../components';
import axios from 'axios';
import { resumeUpload } from '../../../api/Api';

const ProfilePage = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentField, setCurrentField] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');  // State for error message
  const [successMessage, setSuccessMessage] = useState('');  // State for success message
  const [file, setFile] = useState(null);
  const fileInput = useRef(null);

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Clear previous messages
    setErrorMessage('');
    setSuccessMessage('');
  
    if (!file) {
      setErrorMessage('Please select a file to upload.');
      return;
    }
  
    // Create FormData object to send file data
    const formData = new FormData();
    formData.append('file', file);
    formData.append('email', data?.email); // Append the email to the form data
    console.log(formData);
  
    try {
      // Send POST request to the server
      const response = await axios.post(resumeUpload, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Handle successful response
      setSuccessMessage('File uploaded successfully.');
      console.log(response.data);
  
      // Show success message and refresh the page
      alert('File uploaded successfully.');
      window.location.reload(); // Refresh the page
  
    } catch (error) {
      // Check if the error response exists
      if (error.response) {
        // Check if there's a server error message
        if (error.response.data && error.response.data.message) {
          setErrorMessage(error.response.data.message); // Set error message from the backend
        } else {
          setErrorMessage(`Error: ${error.response.status} - ${error.response.statusText}`); // Generic error message with status
        }
      } else if (error.request) {
        // The request was made but no response was received
        setErrorMessage('No response from the server. Please try again later.');
      } else {
        // Something happened in setting up the request
        setErrorMessage('Error in setting up the request: ' + error.message);
      }
    }
  };
  

  const handleEditClick = (field, value) => {
    setCurrentField(field);
    setCurrentValue(value);
    setOpenModal(true);
  };

  const handleUpdate = (updatedData) => {
    console.log(`Updated ${updatedData.key}: ${updatedData.value}`);
    setOpenModal(false);
    window.location.reload();
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className='container-fluid bg-[#F4F2EE]'>
        <div className="container-sm bg-light">
          <div className="row py-4 bg-light">
            <div className="col-lg-2">
              <div className="col py-3">
                <img src="https://i.pravatar.cc/300" alt="" className='w-24 rounded-circle' />
              </div>
            </div>
            <div className="col-lg">
              <h3 className='text-xl font-semibold capitalize'>
                {data?.firstName} {data?.lastName}
              </h3>
              <ul className="nav gap-2">
                <li className='nav-item'>
                  {data?.mobile} |
                </li>
                <li className='nav-item'>
                  {data?.email}
                </li>
              </ul>
              <ul className="nav gap-2">
                <li className='nav-item'>
                  {data?.address?.street} |
                </li>
                <li className='nav-item'>
                  {data?.address?.city}
                </li>
                <li className='nav-item'>
                  {data?.address?.state} |
                </li>
                <li className='nav-item'>
                  {data?.address?.pincode}
                </li>
              </ul>
              <ul className="nav gap-2">
                <li className='nav-item'>
                  {data?.experienceInYear} years of experience |
                </li>
              </ul>
              <ul className="nav items-center gap-2 pt-4">
              <li className='nav-item'>
                {data?.links?.website ? (
                  <a href={data?.links?.website} className='text-2xl' target='_blank' rel="noopener noreferrer"style={{color:'blue', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <TbWorldWww className='w-7 h-6.5'/>
                <p style={{fontSize:'15px'}}>Website</p>
                  </a>
                  ) : null}
                  
                </li>
                <li className='nav-item'>
                {data?.links?.github ? (
                  <a href={data?.links?.github} className='text-2xl' target='_blank' rel="noopener noreferrer"style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <FaGithub />
                    <p style={{fontSize:'15px'}}>Github</p>
                  </a>
                  ) : null}
                 
                </li>
                <li className='nav-item'>
                {data?.links?.linkedin ? (
                  <a href={data?.links?.linkedin} className='text-2xl text-[#0962C0]' target='_blank' rel="noopener noreferrer"style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <FaLinkedin />
                    <p style={{fontSize:'15px'}}>Linkedin</p>
                  </a>
                ) : null}
                
                </li>
                <li className='nav-item'>
                {data?.links?.twitter ?(
                  <a href={data?.links?.twitter} className='text-2xl text-gray-500' target='_blank' rel="noopener noreferrer"style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <FaXTwitter />
                  <p style={{fontSize:'15px'}}>Twitter</p>
                  </a>
                   ) : null}
                   
                </li>
                <li className='nav-item'>
                {data?.links?.portfolio ? (
                  <a href={data?.links?.portfolio} className='text-2xl' target='_blank' rel="noopener noreferrer"style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                   <FaBook/>
                   <p style={{fontSize:'15px'}}>Portfolio</p>
                  </a>
                  ) : null}
                  
                </li>
              </ul>
            </div>
          </div>

          <p className='hr bg-slate-200'></p>
          <div className="row mb-3">
            <div className="col-lg py-3">
              <h4 className='h3'>Experience</h4>
              {data?.experiences?.map((exp, idx) => (
                <div key={idx} className="row py-2">
                  <div className="col-lg">
                    <h4 className='h6'>{exp?.title}</h4>
                    <span>{exp?.company}</span>
                    <ul className="nav">
                      <li className="nav-item">
                        {convertTimeIntoMMYYYY(exp?.startDate)}
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <p className='hr bg-slate-200'></p>
          <div className="row">
            <div className="col-lg py-3">
              <h4 className='h3'>Education</h4>
              {data?.education?.map((exp, idx) => (
                <div key={idx} className="row py-2">
                  <div className="col-lg">
                    <h4 className='h6'>{exp?.title}</h4>
                    <span>{exp?.university}</span>
                    <ul className="nav">
                      <li className="nav-item">
                        {convertTimeIntoMMYYYY(exp?.startDate)} -
                      </li>
                      <li className="nav-item">
                        {convertTimeIntoMMYYYY(exp?.endDate)}
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Resume Upload Section */}
          <p className='hr bg-slate-200'></p>
          <div className="row mb-3">
            <div className="col-lg py-3">
              <h4 className='h3'>Resume</h4>

              {data?.resumeUrl ? (
                <div>
                  <p>Your current resume: <a className='text-prime' href={data.resumeUrl} target="_blank" rel="noopener noreferrer">View/Download Resume</a></p>
                  <p>If you want to upload a different resume, use the option below:</p>
                </div>
              ) : (
                <p>No resume uploaded yet. Please upload one below.</p>
              )}

              <form onSubmit={handleSubmit} className='text text-dark text-14'>
                <input
                  type="file"
                  name="file"
                  placeholder='Supported: .doc, .docx, .pdf'
                  className="form-control w-50 mt-2 rounded"
                  ref={fileInput}
                  onChange={handleFileChange}
                />
                <span className="place mt-3 text-center justify-center text-orange-700 text-12">
                  <b>Supported formats: .doc, .docx, .pdf</b>
                </span><br />
                <button type="submite" className='capital mt-2 bg-prime rounded w-25 h-8 text-white'>Upload</button>
              </form>

              {/* Display error message */}
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              {/* Display success message */}
              {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalBox closeModal={closeModal}>
          <UpdateInput
            title={`Update ${currentField}`}
            defaultValue={currentValue}
            keyName={currentField}
            handleUpdate={handleUpdate}
          />
        </ModalBox>
      )}
    </>
  );
};

export default ProfilePage;

import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useEmployeeContext, useSectionContext } from '../../../context';
import { Multiselect } from 'multiselect-react-dropdown';
import { jobType, locations } from '../../../testData/StaticData';
import MediaQuery from 'react-responsive';
import axios from 'axios';
import  {resumeUpload}  from '../../../api/Api';
import { IconButton } from '@mui/material';
 
const EmployeeProfileForm = () => {
    const { createUserProfile, updateEmployeeDetails } = useEmployeeContext();
    const { getAllSections } = useSectionContext();
    const [sectionData, setSectionData] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = JSON.parse(sessionStorage.getItem('data'));
    const [values, setValues] = useState({
        firstName: userData?.firstName || '',
        lastName: userData?.lastName || '',
        email: userData?.email || '',
        mobile: userData?.mobile || '',
        gender: '',
        dateOfBirth: '',
        bio: '',
        experienceInYear: 0,
        alternateNumber: '',
        resumeUrl: '',
        address: {
            street: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            isPermanent: false
        },
        links: {
            website: '',
            linkedin: '',
            github: '',
            twitter: '',
            portfolio: '',
        },
        experiences: [{
            title: '',
            company: '',
            startDate: '',
            endDate: null,
            description: '',
            isActive: false,
        }],
        education: [{
            title: '',
            university: '',
            startDate: '',
            endDate: null,
            marks: '',
            isActive: false
        }],
        interests: {
            category: [],
            location: [],
            jobType: []
        }
 
    });
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const [section, key] = name.split('.');
        if (key) {
            setValues(prevValues => ({
                ...prevValues,
                [section]: {
                    ...prevValues[section],
                    [key]: type === 'checkbox' ? checked : value,
                },
            }));
        } else {
            setValues(prevValues => ({
                ...prevValues,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };
 
    const handleExperienceChange = (index, e) => {
        const { name, value, type, checked } = e.target;
        const newExperiences = values.experiences.map((experience, i) => {
            if (i === index) {
                return { ...experience, [name]: type === 'checkbox' ? checked : value };
            }
            return experience;
        });
        setValues(prevValues => ({ ...prevValues, experiences: newExperiences }));
    };
 
    const handleEducationChange = (index, e) => {
        const { name, value, type, checked } = e.target;
        const newEducation = values.education.map((education, i) => {
            if (i === index) {
                return { ...education, [name]: type === 'checkbox' ? checked : value };
            }
            return education;
        });
        setValues(prevValues => ({ ...prevValues, education: newEducation }));
    };
 
    const addExperience = () => {
        setValues(prevValues => ({
            ...prevValues,
            experiences: [
                ...prevValues.experiences,
                { title: '', company: '', startDate: '', endDate: '', description: '', isActive: false },
            ],
        }));
    };
    const addEducation = () => {
        setValues(prevValues => ({
            ...prevValues,
            education: [
                ...prevValues.education,
                { title: '', university: '', startDate: '', endDate: '', marks: '', isActive: false },
            ],
        }));
    };
    const getAllJobCategories = async () => {
        setLoading(true);
        try {
            const data = await getAllSections();
            if (data && data?.data.length > 0) {
                setSectionData(data?.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
 
    useEffect(() => {
        getAllJobCategories();
    }, []);
    const handleSelect = (selectedList, selectedItem, type) => {
        if (type === 'category' && selectedList.length > 5) {
            return; // Prevent adding more than 5 items
        }
 
        setValues((prevValues) => ({
            ...prevValues,
            interests: {
                ...prevValues.interests,
                [type]: selectedList.map(item => item.title)
            }
        }));
    };
 
    const handleRemove = (selectedList, removedItem, type) => {
        setValues((prevValues) => ({
            ...prevValues,
            interests: {
                ...prevValues.interests,
                [type]: selectedList.map(item => item.title)
            }
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Clear previous messages
        setErrorMessage('');
        setSuccessMessage('');
      
        // Validate if the file is selected
        // if (!file) {
        //   setErrorMessage('Please select a file to upload.');
        //   return;
        // }
      
        try {
          // Step 1: Create the user profile without the resumeUrl first
          if(!file){
          const result = await createUserProfile(values); // This creates the user in the database
          console.log("User profile created successfully", result);
          setSuccessMessage('Profile uploaded successfully.');
          navigate("/");  // Redirect to another page after success
          }
          
          else{
            const result = await createUserProfile(values); // This creates the user in the database
          console.log("User profile created successfully", result);
            // Step 2: After the user is created, upload the resume
          const formData = new FormData();
          formData.append('file', file);
          formData.append('email', values.email); // Use the created user's email
          console.log(formData);
      
          const resumeResponse = await axios.post(resumeUpload, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
      
          // Extract resume URL from response
          const resumeUrl = resumeResponse.data.resumeUrl;
          
          const updatedValues = { ...values, resumeUrl};
          await updateEmployeeDetails(updatedValues);
          console.log("Profile updated with resume URL", updatedValues);
        //   setSuccessMessage('Profile and resume uploaded successfully.');
        //   navigate("/");  // Redirect to another page after success
      
          // Step 3: Update the user profile with the resumeUrl
        
        //   await updateEmployeeDetails(updatedValues); // This step updates the user profile with the resume URL
        //   console.log("Profile updated without resume URL", updatedValues);
      
          // Success message or redirection
          setSuccessMessage('Profile and resume uploaded successfully.');
          navigate("/");  // Redirect to another page after success
        }
      
        } 
        catch (error) {
          // Handle errors from both profile creation and resume upload
          if (error.response) {
            // Handle server-side errors
            if (error.response.data && error.response.data.message) {
              setErrorMessage(error.response.data.message);
            } else {
              setErrorMessage(`Error: ${error.response.status} - ${error.response.statusText}`);
            }
          } else if (error.request) {
            // Handle no response error
            setErrorMessage('No response from the server. Please try again later.');
          } else {
            // Handle other errors
            setErrorMessage('Error in setting up the request: ' + error.message);
          }
        }
      
        // Log final form values for debugging
        console.log('Form Values:', values);
      };
      
    
 
    const [errorMessage, setErrorMessage] = useState('');  // State for error message
    const [successMessage, setSuccessMessage] = useState('');  // State for success message
    const [file, setFile] = useState(null);
    const fileInput = useRef(null);
   
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);  // Set the selected file in state
    };
 
    return (
        <div className='container-fluid'>
            <div className="container-sm">
                <div className="row">
                    <div className="col-lg">
                        <br/>
                    <p className='flex items-center gap-3 border-2 rounded border-info justify-center items-center'>
                        <h3 className='h3 mt-2 text-dark'><b>Complete your profile</b></h3></p>
                        <form onSubmit={handleSubmit}>
                            <div className='col-lg mb-3'>
                                <label htmlFor="bio">Bio</label>
                                <textarea className='form-control' rows={3} name="bio" value={values.bio} onChange={handleChange}></textarea>
                            </div>
                            <div className="row">
                                <div className="col-lg mb-3">
                                    <label htmlFor="gender">Gender</label>
                                    <select className='form-select' name="gender" value={values.gender} onChange={handleChange}>
                                        <option value="">Choose options</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                                <div className="col-lg mb-3">
                                    <label htmlFor="dateOfBirth">Date of birth</label>
                                    <input type="date" name="dateOfBirth" className='form-control' value={values.dateOfBirth} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="alternateNumber">Alternate number</label>
                                <input type="number" name="alternateNumber" className='form-control' value={values.alternateNumber} onChange={handleChange} />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="experienceInYear">Total experience in years</label>
                                <input type="number" name="experienceInYear" className='form-control' value={values.experienceInYear} onChange={handleChange} />
                            </div>
                            <br/>
                            <p className="hr bg-dark my-3"></p>
                            <div className="address-container mb-3">
                            <p className='flex items-center gap-3 border-2 rounded border-info justify-center items-center'>
                                <h3 className='h3 mt-2  text-dark'><b>Address Information</b></h3></p>
                                <div className="form-group mb-3 mt-3">
                                    <label htmlFor="address.street">Street</label>
                                    <input type="text" name="address.street" className='form-control' value={values.address.street} onChange={handleChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="address.city">City</label>
                                    <input type="text" name="address.city" className='form-control' value={values.address.city} onChange={handleChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="address.state">State</label>
                                    <input type="text" name="address.state" className='form-control' value={values.address.state} onChange={handleChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="address.zip">ZIP Code</label>
                                    <input type="text" name="address.zip" className='form-control' value={values.address.zip} onChange={handleChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="address.country">Country</label>
                                    <input type="text" name="address.country" className='form-control' value={values.address.country} onChange={handleChange} />
                                </div>
                                <div className="form-group mb-3 flex items-center gap-3">
                                    <input type="checkbox" name="address.isPermanent" className='form-check' checked={values.address.isPermanent} onChange={handleChange} />
                                    <label htmlFor="address.isPermanent">Is Permanent</label>
                                </div>
                            </div>
                            <br/>
                            <p className="hr bg-dark my-3"></p>
                            <div className="education-container">
                            <p className='flex items-center gap-3 border-2 rounded border-info justify-center items-center'>
                                    <h3 className='h3 mt-2 text-dark'><b>Education Information</b></h3>
                                   
                                </p>
                                {values.education.map((exp, index) => (
                                    <div key={index} className="experience-entry mb-3 mt-3">
                                        <div className="form-group mb-3">
                                            <label htmlFor={`title-${index}`}>Degree</label>
                                            <input
                                                type="text"
                                                name="title"
                                                className='form-control'
                                                value={exp.title}
                                                onChange={(e) => handleEducationChange(index, e)}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor={`company-${index}`}>University Name</label>
                                            <input
                                                type="text"
                                                name="university"
                                                className='form-control'
                                                value={exp.university}
                                                onChange={(e) => handleEducationChange(index, e)}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-lg mb-3">
                                                <label htmlFor={`startDate-${index}`}>Start Date</label>
                                                <input
                                                    type="date"
                                                    name="startDate"
                                                    className='form-control'
                                                    value={exp.startDate}
                                                    onChange={(e) => handleEducationChange(index, e)}
                                                />
                                            </div>
                                            <div className="col-lg mb-3">
                                                <label htmlFor={`endDate-${index}`}>End Date</label>
                                                <input
                                                    type="date"
                                                    name="endDate"
                                                    className='form-control'
                                                    value={exp.endDate}
                                                    onChange={(e) => handleEducationChange(index, e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor={`marks-${index}`}>Marks</label>
                                            <input
                                                type="text"
                                                name="marks"
                                                className='form-control'
                                                value={exp.marks}
                                                onChange={(e) => handleEducationChange(index, e)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 flex items-center gap-3">
                                            <input
                                                type="checkbox"
                                                name="isActive"
                                                className='form-check'
                                                checked={exp.isActive}
                                                onChange={(e) => handleEducationChange(index, e)}
                                            />
                                            <label htmlFor={`isActive-${index}`}>Is Active</label>
                                        </div>
                                    </div>
                                ))}
                                <MediaQuery query="(max-width: 786px)">
                                <p className='d-grid gap-2 col-5 mx-auto'><button className='btn btn-primary'><Link onClick={addEducation} className='nav-link text-light py-[-2]'><b>Add new</b>
                                </Link></button></p></MediaQuery>
 
                                <MediaQuery query="(min-width: 787px)">
                                 <p className='d-grid gap-2 col-2 mx-auto'>
                                 <button className='btn btn-primary'>
                                <Link onClick={addEducation} className='nav-link text-light py-[-2]'><b>Add new</b></Link>
                                </button></p></MediaQuery>
                                 
                                <br/>
                            </div>
                            <p className="hr bg-dark my-3"></p>
                            <div className="experience-container">
                            <p className='flex items-center gap-3 border-2 rounded border-info justify-center items-center'>
                                    <h3 className='h3 mt-2 text-dark'><b>Experience Information</b></h3>
                                </p>
                                {values.experiences.map((exp, index) => (
                                    <div key={index} className="experience-entry mb-3 mt-3">
                                        <div className="form-group mb-3">
                                            <label htmlFor={`title-${index}`}>Job Title</label>
                                            <input
                                                type="text"
                                                name="title"
                                                className='form-control'
                                                value={exp.title}
                                                onChange={(e) => handleExperienceChange(index, e)}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor={`company-${index}`}>Company Name</label>
                                            <input
                                                type="text"
                                                name="company"
                                                className='form-control'
                                                value={exp.company}
                                                onChange={(e) => handleExperienceChange(index, e)}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-lg mb-3">
                                                <label htmlFor={`startDate-${index}`}>Start Date</label>
                                                <input
                                                    type="date"
                                                    name="startDate"
                                                    className='form-control'
                                                    value={exp.startDate}
                                                    onChange={(e) => handleExperienceChange(index, e)}
                                                />
                                            </div>
                                            <div className="col-lg mb-3">
                                                <label htmlFor={`endDate-${index}`}>End Date</label>
                                                <input
                                                    type="date"
                                                    name="endDate"
                                                    className='form-control'
                                                    value={exp.endDate}
                                                    onChange={(e) => handleExperienceChange(index, e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor={`description-${index}`}>Description</label>
                                            <input
                                                type="text"
                                                name="description"
                                                className='form-control'
                                                value={exp.description}
                                                onChange={(e) => handleExperienceChange(index, e)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 flex items-center gap-3">
                                            <input
                                                type="checkbox"
                                                name="isActive"
                                                className='form-check'
                                                checked={exp.isActive}
                                                onChange={(e) => handleExperienceChange(index, e)}
                                            />
                                            <label htmlFor={`isActive-${index}`}>Is Active</label>
                                        </div>
                                    </div>
                                ))}
                                 <MediaQuery query="(max-width: 786px)">
                                <p className='d-grid gap-2 col-5 mx-auto'><button className='btn btn-primary'><Link onClick={addEducation} className='nav-link text-light py-[-2]'><b>Add new</b>
                                </Link></button></p></MediaQuery>
 
                                <MediaQuery query="(min-width: 787px)">
                                 <p className='d-grid gap-2 col-2 mx-auto'>
                                 <button className='btn btn-primary'>
                                <Link onClick={addExperience} className='nav-link text-light py-[-2]'><b>Add new</b></Link>
                                </button></p></MediaQuery>
                                <br/>
                            </div>
                            <p className="hr bg-dark my-3"></p>
                            <div className="links-container mb-3">
                            <p className='flex items-center gap-3 border-2 rounded border-info justify-center items-center'>
                                <h3 className='h3 mt-2 text-dark'><b>Social Links</b></h3></p>
                                <div className="form-group mb-3 mt-3">
                                    <label htmlFor="links.website">Website</label>
                                    <input type="text" name="links.website" className='form-control' value={values.links.website} onChange={handleChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="links.linkedin">LinkedIn Profile URL</label>
                                    <input type="text" name="links.linkedin" className='form-control' value={values.links.linkedin} onChange={handleChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="links.github">Github</label>
                                    <input type="text" name="links.github" className='form-control' value={values.links.github} onChange={handleChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="links.twitter">Twitter</label>
                                    <input type="text" name="links.twitter" className='form-control' value={values.links.twitter} onChange={handleChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="links.portfolio">Portfolio</label>
                                    <input type="text" name="links.portfolio" className='form-control' value={values.links.portfolio} onChange={handleChange} />
                                </div>
                            </div>
                            <br/>
                            <p className="hr bg-dark my-3"></p>
                            <div className="links-container mb-3">
                            <p className='flex items-center gap-3 border-2 rounded border-info justify-center items-center'>
                                <h3 className='h3 mt-2 text-dark'><b>Preferences</b></h3></p>
                                <div className="form-group mb-3 mt-3">
                                    <label htmlFor="links.website">Preferred Category</label>
                                    <Multiselect
                                        options={sectionData} // Options to display in the dropdown
                                        selectedValues={sectionData.filter(item => values.interests.category.includes(item.title))} // Preselected values
                                        onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem, 'category')}
                                        onRemove={(selectedList, removedItem) => handleRemove(selectedList, removedItem, 'category')}
                                        displayValue="title" // Property name to display in the dropdown options
                                        loading={loading}
                                        disablePreSelectedValues
                                        style={{
                                            chips: {
                                                background: values.interests.category.length >= 5 ? 'gray' : undefined
                                            },
                                            multiselectContainer: {
                                                color: values.interests.category.length >= 5 ? 'red' : undefined
                                            }
                                        }}
                                    />                              </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="links.linkedin">Preferred Location</label>
                                    <Multiselect
                                        options={locations} // Options to display in the dropdown
                                        selectedValues={locations.filter(item => values.interests.location.includes(item.title))} // Preselected values
                                        onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem, 'location')}
                                        onRemove={(selectedList, removedItem) => handleRemove(selectedList, removedItem, 'location')}
                                        displayValue="title" // Property name to display in the dropdown options
                                        loading={loading}
                                    />                              </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="links.github">Preferred Job Type</label>
                                    <Multiselect
                                        options={jobType} // Options to display in the dropdown
                                        selectedValues={jobType.filter(item => values.interests.jobType.includes(item.title))} // Preselected values
                                        onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem, 'jobType')}
                                        onRemove={(selectedList, removedItem) => handleRemove(selectedList, removedItem, 'jobType')}
                                        displayValue="title" // Property name to display in the dropdown options
                                        loading={loading}
                                    />                               </div>
                                   <div className="col-lg mb-6 ">
    <br />
    {/* Include the file input inside the main profile form */}
    <div className='up text-dark text-14'><b>Upload Resume</b>
        <input
            type="file"
            name="file"
            placeholder='supported .doc, .docx, .pdf file'
            className="form-control w-50 mt-2 rounded"
            ref={fileInput}  // Make sure to define fileInput using useRef()
            onChange={handleFileChange}  // Handle file change to set the file
        />
        <span className="place mt-3 text-center justify-center text-orange-700 text-12">
            <b>Supported formats: .doc, .docx, .pdf</b>
        </span>
        <br />
        {/* Display error message */}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {/* Display success message */}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
    </div>
    <br />
</div>

                            </div>
                            <div className="form-group py-2 d-grid gap-2 col-2 mx-auto mb-3">
                                <button  type="submite" className='btn btn-primary'><b>Submit</b></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
 
export default EmployeeProfileForm;
